import './bootstrap';
import {createRoot} from 'react-dom/client';
import React, {useEffect, useState} from 'react';
import logo from '../assets/svgs/logo.svg';
import {Main} from './pages/main/main';
import {
  createBrowserRouter,
  RouterProvider,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import {Day} from '@/pages/day/day.tsx';
import {
  useInitData,
  WebAppProvider,
} from '@vkruglikov/react-telegram-web-app';
import {Theme} from '@/pages/theme/theme.tsx';
import {Section} from '@/pages/section';
import {clsx} from 'clsx';
import {Menu} from '@/ui/menu/menu.tsx';
import {Search} from '@/pages/search/search.tsx';
import {ReviewPage} from '@/pages/review';
import {Help} from '@/pages/help';
import {GameComponent} from '@/game/game-component.tsx';
import {RatingTable} from '@/game/rating.tsx';
import {ActionBar} from "@/ui/sochi/action-bar.tsx";
import {AboutPage} from "@/pages/about/page.tsx";
import {RulesPage} from "@/pages/rules/page.tsx";

interface LayoutProps {
  children: React.ReactNode;
  isGame?: boolean;
  isMain?: boolean;
}

const Layout: React.FC<LayoutProps> = ({children, isMain, isGame}) => {
  const [user, setUser] = useState<number | undefined>(undefined);
  const [_, initData] = useInitData();

  useEffect(() => {
    fetch(`/api/auth`, {
      method: 'POST',
      body: initData,
    })
      .then((response) => response.json())
      .then((data) => {
        return data.success ? setUser(data.user_id) : setUser(undefined);
      });
  }, []);

  useEffect(() => {
    console.log(
      `layout: user: ${user}, initData: ${initData}, userAgent:${navigator.userAgent} `,
    );
  }, [user]);


  if (isGame) {
    return (
      <div className="flex flex-col min-h-screen font-sans relative">
        {children}
      </div>
    );
  }

  return (
    <div
      className={clsx('mx-auto relative max-w-[350px] w-full px-4 flex flex-col bg-white min-h-screen pt-4 font-sans')}
    >
      {children}

      {<ActionBar/>}
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Layout isMain={true}>
        <Main/>
      </Layout>
    ),
  },
  {
    path: '/game',
    element: (
      <Layout isGame={true}>
        <GameComponent/>
      </Layout>
    ),
  },
  {
    path: '/game/rating',
    element: (
      <Layout>
        <RatingTable/>
      </Layout>
    ),
  },
  {
    path: '/about',
    element: (
      <Layout>
        <AboutPage/>
      </Layout>
    ),
  },
  {
    path: '/rules',
    element: (
      <Layout>
        <RulesPage/>
      </Layout>
    ),
  },
  {
    path: '/help',
    element: (
      <Layout>
        <Help/>
      </Layout>
    )
  }

]);

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WebAppProvider>
      <RouterProvider router={router}/>
    </WebAppProvider>
  </React.StrictMode>,
);
