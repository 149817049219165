import {EventBus} from '../EventBus';
import {Scene} from 'phaser';
import {
  gameOverStore,
  gameStore,
  GameStore,
  setGameOver,
  setTestStore,
  testQuestionsStore,
} from '@/game/game-store.tsx';
import {StoreWritable} from 'effector';

export class Game extends Scene {
  background!: Phaser.Physics.Arcade.Group;
  obstacles!: Phaser.Physics.Arcade.Group;
  questions!: Phaser.Physics.Arcade.Group;
  player!: Phaser.Physics.Arcade.Sprite;
  cop!: Phaser.Physics.Arcade.Sprite;
  cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  platform!: Phaser.Physics.Arcade.Group;
  isStop: boolean = false;
  Y_ZERO_OBSTACLES: number = this.isAndroid() ? 530 : 550;
  gameData: StoreWritable<GameStore | null>;
  attempt: number = 2;
  gameOverScreen: StoreWritable<Boolean | null>;
  isOpenModal: StoreWritable<Boolean | null>;
  colliderPlayerAndQuestions!: Phaser.Physics.Arcade.Collider;

  backgroundSound!: Phaser.Sound.BaseSound;
  jumpSound!: Phaser.Sound.BaseSound;
  questionSound!: Phaser.Sound.BaseSound;
  punchSound!: Phaser.Sound.BaseSound;
  gameOverEvent?: Phaser.Time.TimerEvent;
  badAnswerTimeEvent?: Phaser.Time.TimerEvent;
  previousPlayerX: number = 0;
  previousPlayerY: number = 0;
  isPunchOnBadAnswer: boolean = false;
  isColliderActive: boolean = true;

  constructor() {
    super('Game');
    this.gameData = gameStore;
    this.isOpenModal = testQuestionsStore;
    this.gameOverScreen = gameOverStore;
  }

  isAndroid(): boolean {
    return navigator.userAgent.match(/Android/i) !== null;
  }

  preload() {


    this.load.image('bg_1', '/game-assets/sochi/backgrounds/1.png');
    this.load.image('bg_2', '/game-assets/sochi/backgrounds/2.png');
    this.load.image('bg_3', '/game-assets/sochi/backgrounds/3.png');
    this.load.image('bg_4', '/game-assets/sochi/backgrounds/4.png');
    this.load.image('bg_5', '/game-assets/sochi/backgrounds/5.png');
    this.load.image('bg_6', '/game-assets/sochi/backgrounds/6.png');
    this.load.image('bg_7', '/game-assets/sochi/backgrounds/7.png');


    this.load.image(
      'obstacle1',
      '/game-assets/sochi/obstacles/obstacles_1.png',
    );
    this.load.image(
      'obstacle2',
      '/game-assets/sochi/obstacles/obstacles_2.png',
    );
    this.load.image(
      'obstacle3',
      '/game-assets/sochi/obstacles/obstacles_3.png',
    );
    this.load.image(
      'obstacle4',
      '/game-assets/sochi/obstacles/obstacles_4.png',
    );
    this.load.image(
      'obstacle5',
      '/game-assets/sochi/obstacles/obstacles_5.png',
    );
    this.load.image(
      'obstacle6',
      '/game-assets/sochi/obstacles/obstacles_6.png',
    );
    this.load.image(
      'obstacle8',
      '/game-assets/sochi/obstacles/obstacles_8.png',
    );
    this.load.image(
      'obstacle9',
      '/game-assets/sochi/obstacles/obstacles_9.png',
    );
    this.load.image(
      'obstacle11',
      '/game-assets/sochi/obstacles/obstacles_11.png',
    );
    this.load.image(
      'obstacle12',
      '/game-assets/sochi/obstacles/obstacles_12.png',
    );
    this.load.image(
      'obstacle13',
      '/game-assets/sochi/obstacles/obstacles_13.png',
    );
    this.load.image(
      'obstacle14',
      '/game-assets/sochi/obstacles/obstacles_14.png',
    );
    this.load.image(
      'obstacle15',
      '/game-assets/sochi/obstacles/obstacles_15.png',
    );
    this.load.image('platform', '/game-assets/sochi/dev/platform.png');

    this.load.image(
      'question-img',
      '/game-assets/sochi/obstacles/question_1.png',
    );

    this.load.audio('background', '/game-assets/sounds/background.mp3');
    this.load.audio('jump', '/game-assets/sounds/jump.mp3');
    this.load.audio('question', '/game-assets/sounds/get-question.mp3');
    this.load.audio('punch', '/game-assets/sounds/punch2.mp3');

    this.load.spritesheet(
      'dude',
      this.gameData.getState()?.gender === 'male'
        ? '/game-assets/sochi/characters/man.png'
        : '/game-assets/sochi/characters/woman.png',
      {
        frameWidth: 82,
        frameHeight: 133,
      },
    );

    this.load.spritesheet('cop', '/game-assets/sochi/characters/goosev3.png', {
      frameHeight: 124,
      frameWidth: 82,
    });
  }

  createObstacles(): void {
    this.obstacles = this.physics.add.group({
      immovable: true,
      allowGravity: false,
      collideWorldBounds: false,
      gravityX: Infinity,
    });

    for (let i = 0; i < 3; i++) {
      const obstacle = this.obstacles.create(
        420 + i * 500,
        this.Y_ZERO_OBSTACLES,
        'obstacle3',
      );

      const obstacle2 = this.obstacles.create(
        500 + i * 500,
        this.Y_ZERO_OBSTACLES,
        'obstacle5',
      );

      obstacle.setScale(0.2);
      obstacle.setVelocityX(-160);

      obstacle2.setScale(0.2);
      obstacle2.setVelocityX(-160);
    }

    if (!this.isAndroid()) {
      for (let i = 0; i < 3; i++) {
        const obstacle = this.obstacles.create(
          2300 + i * 500,
          this.Y_ZERO_OBSTACLES - 30,
          'obstacle12',
        );
        const obstacle2 = this.obstacles.create(
          3100 + i * 650,
          this.Y_ZERO_OBSTACLES - 30,
          'obstacle13',
        );
        obstacle.setScale(0.5);
        obstacle.setVelocityX(-160);

        obstacle2.setScale(0.5);
        obstacle2.setVelocityX(-160);
      }

      for (let i = 0; i < 5; i++) {
        const obstacle = this.obstacles.create(
          4800 + i * 590,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle11',
        );

        const obstacle2 = this.obstacles.create(
          5000 + i * 500,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle9',
        );

        obstacle.setScale(0.2);
        obstacle.setVelocityX(-160);

        obstacle2.setScale(0.2);
        obstacle2.setVelocityX(-160);
      }

      for (let i = 0; i < 6; i++) {
        const obstacle = this.obstacles.create(
          7000 + i * 500,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle1',
        );

        const obstacle2 = this.obstacles.create(
          7220 + i * 500,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle2',
        );

        obstacle.setScale(0.2);
        obstacle.setVelocityX(-160);

        obstacle2.setScale(0.2);
        obstacle2.setVelocityX(-160);
      }

      for (let i = 0; i < 4; i++) {
        const obstacle = this.obstacles.create(
          11000 + i * 500,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle6',
        );

        const obstacle2 = this.obstacles.create(
          12000 + i * 400,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle8',
        );

        obstacle.setScale(0.2);
        obstacle.setVelocityX(-160);

        obstacle2.setScale(0.2);
        obstacle2.setVelocityX(-160);
      }

      for (let i = 0; i < 4; i++) {
        const obstacle = this.obstacles.create(
          12600 + i * 500,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle14',
        );

        const obstacle2 = this.obstacles.create(
          13000 + i * 400,
          this.Y_ZERO_OBSTACLES - 20,
          'obstacle15',
        );

        obstacle.setScale(0.2);
        obstacle.setVelocityX(-160);

        obstacle2.setScale(0.2);
        obstacle2.setVelocityX(-160);
      }
    }
  }

  activateCollider() {
    // console.log(this.game.isPaused);
    if (this.game.isPaused) {
      // console.log('COLLIDER DEACTIVATED IN 2 SECONDS');
      setTimeout(() => this.activateCollider(), 2000);
      return;
    }
    // console.log('COLLIDER ACTIVATED');
    this.isColliderActive = true;
  }

  deactivateCollider() {
    // console.log('COLLIDER DEACTIVATED');
    this.isColliderActive = false;
    setTimeout(() => this.activateCollider(), 6000); // 6 seconds delay
  }

  createQuestions(): void {
    for (let i = 0; i < 50; i++) {
      // console.log(i);
      this.questions.create(
        600 + i * 666,
        this.Y_ZERO_OBSTACLES,
        'question-img',
      );
    }
  }

  create() {
    this.platform = this.physics.add.group({
      immovable: true,
      allowGravity: false,
    });

    this.questions = this.physics.add.group({
      immovable: true,
      allowGravity: false,
    });

    this.backgroundSound = this.sound.add('background', {
      loop: true,
      volume: 0.1,
    });

    this.punchSound = this.sound.add('punch', {
      loop: true,
      volume: 0.4,
    });

    this.jumpSound = this.sound.add('jump', {
      loop: false,
      volume: 0.1,
    });

    this.questionSound = this.sound.add('question', {
      loop: false,
      volume: 0.1,
    });

    this.backgroundSound.play();

    if (this.isAndroid()) {
      this.platform.create(100, 660, 'platform');
    } else {
      this.platform.create(100, 660, 'platform');
    }

    this.platform.scaleY(4);
    this.platform.setAlpha(0);

    this.background = this.physics.add.group({
      immovable: true,
      allowGravity: false,
    })

    for (let i = 0; i < 7; i++) {
      this.background.create(1000 + 2048 * i, 400, `bg_${i + 1}`);
    }

    this.background.getChildren()[6].setX(this.background.getChildren()[6].x - 162);

    //@ts-ignore

    this.createQuestions();

    if (this.isAndroid()) {
      this.player = this.physics.add
        .sprite(170, 100, 'dude')
        .setDisplaySize(70, 170)
        .refreshBody();
    } else {
      this.player = this.physics.add.sprite(170, 100, 'dude').refreshBody();
    }

    this.cop = this.physics.add
      .sprite(80, 100, 'cop')
      .setScale(0.5, 0.5)
      .refreshBody();

    this.player.setCollideWorldBounds(true);
    this.player.setGravityY(700);

    this.cop.setCollideWorldBounds(false);
    this.cop.setGravityY(700);

    this.anims.create({
      key: 'stop',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 0,
        end: 1,
      }),
      frameRate: 1,
      repeat: -1,
    });

    this.anims.create({
      key: 'right',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 0,
        end: 17,
      }),
      frameRate: 11,
      repeat: -1,
    });

    this.anims.create({
      key: 'right-cop',
      frames: this.anims.generateFrameNumbers('cop', {
        start: 0,
        end: 19,
      }),
      frameRate: 20,
      repeat: -1,
    });

    this.anims.create({
      key: 'punch-cop',
      frames: this.anims.generateFrameNumbers('cop', {
        start: 0,
        end: 19,
      }),
      frameRate: 20,
      repeat: -1,
    });

    this.anims.create({
      key: 'punch',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 0,
        end: 1,
      }),
      frameRate: 20,
      repeat: -1,
    });

    this.anims.create({
      key: 'up',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 1,
        end: 3,
      }),
      frameRate: 6,
      repeat: 0,
    });

    this.createObstacles();

    this.physics.add.collider(this.platform, this.player);
    this.physics.add.collider(this.platform, this.cop);

    this.physics.add.collider(this.player, this.obstacles, () => {
      this.isStop = true;
    });

    // ! info: this function not work
    // this.colliderPlayerAndQuestions = this.physics.add.collider(
    //   this.player,
    //   this.questions,
    //   (e) => {
    //     console.log(e);
    //     if (this.isColliderActive) {
    //       setTestStore(true);
    //       this.questionSound.play();
    //       this.isStop = true;
    //       this.deactivateCollider();
    //     }
    //   },
    // );

    this.physics.add.collider(this.platform, this.obstacles);
    this.physics.add.collider(this.platform, this.questions);
    this.physics.add.collider(
      this.player,
      this.questions,
      this.handleCollision,
      undefined,
      this,
    );

    this.cursors =
      this.input.keyboard?.createCursorKeys() as Phaser.Types.Input.Keyboard.CursorKeys;

    this.physics.collide(this.player, this.platform);
    this.attempt = this.gameData.getState()!.attempts;
    EventBus.emit('current-scene-ready', this);
  }

  handleCollision(player: any, question: any) {
    console.log('COLLISION', {player, question});
    setTestStore(true);
    this.questionSound.play();
    this.isStop = true;
    question.destroy();
  }

  update(): void {
    if (this.isOpenModal.getState()) {
      this.game.pause();
    } else {
      this.game.resume();
    }


    // console.log(this.background.x);

    if (this.gameData.getState()!.attempts < this.attempt) {
      console.log('ATTEMPT', {
        state: this.gameData.getState()?.attempts,
        attempt: this.attempt,
      });
      this.attempt = this.gameData.getState()!.attempts;
      this.isPunchOnBadAnswer = true;
    }

    if (!this.isPunchOnBadAnswer) {
      if (this.cop.x < this.player.x - 50) {
        this.cop.setVelocityX(-60);
      }

      if (this.player.x < 80 && this.player.y < 230) {
        this.player.setVelocityX(180);
        this.cop.setVelocityX(170);
      } else {
        this.player.setVelocityX(0);
        this.cop.setVelocityX(0);
      }

      if (this.player.y < 480) {
        this.isStop = false;
      }

      if (this.isStop) {
        if (this.cop.x < this.player.x - 45) {
          this.cop.setVelocityX(70);
          this.player.anims.play('stop');
        } else {
          this.cameras.main.flash(200, 255, 0, 0);

          this.gameOverEvent = this.time.addEvent({
            delay: 1300,
            callback: this.gameOver,
            callbackScope: this,
          });

          this.previousPlayerX = this.player.x;
          this.previousPlayerY = this.player.y;

          this.cop.setVelocityX(0);
          this.player.anims.play('punch', true);
          this.cop.anims.play('punch-cop', true);
        }
        this.punchSound.stop();
        this.background.setVelocityX(0);
        this.obstacles.setVelocityX(0);
        this.questions.setVelocityX(0);
      }


      if (this.background.getChildren()[this.background.getChildren().length - 1].x <= 600) {
        this.backgroundSound.stop();
        this.isColliderActive = true;
        this.scene.restart();
        this.isColliderActive = true;
        // this.background.setX(7100);
        // this.obstacles.getChildren().forEach((obstacle) => {
        //   obstacle.destroy()
        // });
        //
        // this.createObstacles();
        //
        // this.physics.add.collider(this.player, this.obstacles, () => {
        //   this.isStop = true;
        // });
        //
        // this.colliderPlayerAndQuestions = this.physics.add.collider(
        //   this.player,
        //   this.questions,
        //   () => {
        //     setTestStore(true);
        //     this.questionSound.play();
        //     this.isStop = true;
        //   },
        // );
        //
        // this.player.setX(170);
        // this.cop.setX(140);
      }


      // if (this.isAndroid() && 10000 < -900) {
      //   this.backgroundSound.stop();
      //   this.isColliderActive = true;
      //   this.scene.restart();
      //   this.isColliderActive = true;
      //
      //   // this.background.setX(1000);
      //   // this.obstacles.getChildren().forEach((obstacle) => {
      //   //   obstacle.destroy()
      //   // });
      //   //
      //   // this.questions.getChildren().forEach((question) => {
      //   //   question.destroy()
      //   // });
      //   //
      //   //
      //   // this.createQuestions();
      //   // this.createObstacles();
      //   //
      //   //
      //   // this.physics.add.collider(this.player, this.obstacles, () => {
      //   //   this.isStop = true;
      //   // });
      //   //
      //   // this.player.setX(170);
      //   // this.cop.setX(140);
      // }

      if (!this.isStop) {
        if (this.cop.x <= this.player.x - 10) {
          this.cop.setVelocityX(-10);
        } else {
          this.cop.setVelocityX(0);
        }

        this.background.setVelocityX(-160);
        this.obstacles.setVelocityX(-160);
        this.questions.setVelocityX(-160);
        this.player.anims.play('right', true);
        this.cop.anims.play('right-cop', true);
      }

      // @ts-ignore
      let jumpCooldown = false;

      if (
        (this.cursors.up.isDown ||
          this.input.mousePointer.isDown ||
          this.input.pointer1.isDown) &&
        // @ts-ignore
        this.player.body.touching.down &&
        !jumpCooldown
      ) {
        console.log('Jump triggered:', this.player.y, this.player.x);
        this.jumpSound.play();
        this.player.setVelocityY(-480);
        this.player.setVelocityX(+15);
        this.player.anims.pause();
        this.player.anims.play('up', true);

        if (!this.isAndroid()) {
          jumpCooldown = true;
          setTimeout(() => {
            jumpCooldown = false;
          }, 500);
        }
      }
    } else {
      this.punchOnBadAnswer();
    }
  }

  punchOnBadAnswer() {
    this.player.setX(190);
    this.cop.setX(190);
    this.colliderPlayerAndQuestions.active = false;

    if (this.cop.x < this.player.x - 45) {
      this.cop.setVelocityX(100);
      this.player.anims.play('stop');
    } else {
      this.cameras.main.flash(200, 255, 0, 0);

      this.badAnswerTimeEvent = this.time.addEvent({
        delay: 700,
        callback: this.badAnswerTimeEventCallBack,
        callbackScope: this,
      });

      this.cop.setVelocityX(0);
      this.player.anims.play('punch', true);
      this.cop.anims.play('punch-cop', true);
    }
    this.gameOverEvent?.remove();
    this.gameOverEvent = undefined;

    this.punchSound.stop();
    this.background.setVelocityX(0);
    this.obstacles.setVelocityX(0);
    this.questions.setVelocityX(0);
  }

  badAnswerTimeEventCallBack() {
    console.log('BAD ANSWER TIME EVENT');
    this.colliderPlayerAndQuestions.active = true;
    this.isPunchOnBadAnswer = false;
    this.isStop = false;
    this.cop.setX(140);
    this.player.setX(177);
  }

  startGame() {
    this.scene.start('Game');
  }

  gameOver() {
    if (this.isPunchOnBadAnswer) {
      this.gameOverEvent?.remove();
      this.gameOverEvent = undefined;
      return;
    }

    if (
      (this.player.x !== this.previousPlayerX ||
        this.player.y !== this.previousPlayerY) &&
      this.gameData.getState()?.attempts !== 0
    ) {
      this.gameOverEvent?.remove();
      this.gameOverEvent = undefined;
      setGameOver(false);
    } else {
      setGameOver(true);
      console.log('GAME OVER');
    }
  }
}
