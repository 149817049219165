import React from 'react';
import {Logo} from "@/ui/sochi/icons.tsx";
import {Menu} from "@/ui/sochi/menu.tsx";

const Main: React.FC = () => {

  return (
    <>
      <main className="bg-white font-mono flex overflow-hidden flex-col items-center px-4 pt-8">
        <div className="flex relative w-full h-[calc(100vh-60px)] flex-col items-center max-w-[600px]">
          <Logo/>
          <Menu/>
        </div>
      </main>
    </>
  );
};

export {Main};
